import { createContext,useContext,useState } from "react";
import {useEffect } from "react";
import React from "react";
import * as fetchIntercept from 'fetch-intercept';
const UserContext = createContext(undefined);
export const UserDetailsProvider = ({children}) => {

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const unregister = fetchIntercept.register({
      request: function (url, config) {
        setIsLoading(true);
        return [url, config];
      },
    
      requestError: function (error) {
        return Promise.reject(error);
      },
    
      response: function (response) {
        setIsLoading(false);
        return response;
      },
    
      responseError: function (error) {
        setIsLoading(false);
        return Promise.reject(error);
      },
    });
  },[]);

    var [state,setState] = useState(
        {
            isLoggedIn: false
        });

    return (
      <UserContext.Provider value={
        {
          ...state,
          onLogIn : (isLogIn) => {setState({...state,isLoggedIn:isLogIn})}
        }
        }>
         { React.Children.map(children, (child) => {
              var renderable = React.isValidElement(child);
              if (renderable) {
                return React.cloneElement(child, {
                  showIsLoading:isLoading
                });
              }
            })
          }
      </UserContext.Provider>
    );
  };

  export const useUserContext = () => useContext(UserContext);

