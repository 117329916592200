import {
  Button,
  Heading,
  Flex,
  Card,
  CardBody,
  Stack,
  Divider,
  CardFooter,
  ButtonGroup,
  Image,
  Text,
  IconButton,
  chakra,
  useToast,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Center,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import httpService from "../../services/httpService";
import CommonButtonGroup from "../CommonButtonGroup";
import ModalDialog from "../Forms/ModalDialog";
import AddCategoryForm from "../Forms/AddCategoryForm";
import { RxOpenInNewWindow } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

function ShowAllCategories(props) {
  const [categories, setCategories] = useState([]);
  const [lastSelectedCategoryData, setLastSelectedCategoryData] =
    useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [operation, setOperation] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const CFaUserAlt = chakra(RxOpenInNewWindow);

  function handleError(msg) {
    if (msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }

  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }

  useEffect(() => {
    httpService
      .get("categories/getAllCategories", setCategories)
      .then(() => {})
      .catch((msg) => {
        handleError(msg);
      });
  }, []);

  function closePopup(json) {
    setIsClose(true);
    setIsOpen(false);
    setOperation("");
  }
  function handleDelete() {
    if (lastSelectedCategoryData) {
      httpService
        .post(
          "categories/admin/deleteCategory/" +
            lastSelectedCategoryData.category.id
        )
        .then(() => {
          var clonedData = [...categories];
          clonedData.splice(lastSelectedCategoryData.index, 1);
          showToast("Category deleted", "success");
          setCategories(clonedData);
        })
        .catch(() => {
          toast({
            title:
              "Delete all products added to this category and then try again",
            status: "error",
            isClosable: true,
          });
        });
    }
  }

  function handleAdd() {
    setIsOpen(true);
    setIsClose(false);
    setOperation("add");
  }

  function handleResponse(json) {
    if (json) {
      var clonedData = [...categories];
      if (operation!=='add'&& lastSelectedCategoryData && lastSelectedCategoryData.category) {
        clonedData[lastSelectedCategoryData.index] = json;
      } else {
        clonedData.push(json);
      }
      setCategories(clonedData);
    }
  }

  function handleUpdate() {
    if (lastSelectedCategoryData) {
      var clonedData = { ...lastSelectedCategoryData };
      clonedData.operation = "update";
      setLastSelectedCategoryData(clonedData);
    }
    setIsOpen(true);
  }

  function openProductsPage(category) {
    navigate("/products", {
      state: {
        selectedCategoryId: category.id,
        name: category.name,
        purity: category.purity,
      },
    });
  }

  function handleCardSelection(e, category, index) {
    if (e.currentTarget) {
      if (e.currentTarget.style.backgroundColor === "rgb(190, 227, 248)") {
        e.currentTarget.style.backgroundColor = "#FFFFFF";
        e.currentTarget.style.borderWidth = "0px";
        e.currentTarget.style.borderColor = "#FFFFFF";
        setLastSelectedCategoryData(undefined);
      } else {
        e.currentTarget.style.backgroundColor = "#BEE3F8";
        e.currentTarget.style.borderWidth = "2px";
        e.currentTarget.style.borderColor = "#BEE3F8";

        var clonedData = { ...lastSelectedCategoryData };
        clonedData.category = category;
        clonedData.domElement = e.currentTarget;
        clonedData.index = index;
        setLastSelectedCategoryData(clonedData);
        if (lastSelectedCategoryData && lastSelectedCategoryData.domElement) {
          lastSelectedCategoryData.domElement.style.backgroundColor = "#FFFFFF";
          lastSelectedCategoryData.domElement.style.borderColor = "#FFFFFF";
          lastSelectedCategoryData.domElement.style.borderWidth = "0px";
        }
      }
    }
  }

  return (
    <>
      { (
        <Box pt={2}>
          <CommonButtonGroup
            isShowAdd="true"
            isShowUpdate="true"
            isShowDelete="true"
            deleteFunction={handleDelete}
            addFunction={handleAdd}
            updateFunction={handleUpdate}
            selectedObject={lastSelectedCategoryData}
          ></CommonButtonGroup>
        </Box>
      )}
      <Flex direction="row" flexWrap="wrap">
        {categories.map((category, index) => {
          return (
            <Card
              borderColor="gray.200"
              borderWidth={1}
              mr={2}
              mt={2}
              onClick={(e) => {
                handleCardSelection(e, category, index);
              }}
              _hover={{ bg: "blue.100", cursor: "pointer" }}
              width="200px"
            >
              {category.images && category.images.length > 0 ? (
                <Image
                  height={175}
                  src={category.images[0].url}
                  alt="No Image Provided"
                  borderRadius="5"
                />
              ) : (
                <Image
                  height={175}
                  src=""
                  alt="No Image Provided"
                  borderRadius="5"
                />
              )}
              <CardBody>
                <Stack spacing="1">
                  <Center mb="3px"> <Heading size="sm">{category.name}</Heading></Center>
                  <Text color="blue.700" fontSize="sm">
                    Description : {category.description.length > 100
                      ? category.description.substring(0, 97) + "..."
                      : category.description}
                  </Text>
                  <Text color="blue.700" fontSize="sm">
                    Purity : {category.purity}
                  </Text>
                </Stack>
              </CardBody>
              <CardFooter p={0} justify="flex-end">
                <IconButton
                  onClick={() => {
                    openProductsPage(category);
                  }}
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="See menu"
                  icon={<CFaUserAlt />}
                />
              </CardFooter>
            </Card>
          );
        })}
      </Flex>

      <ModalDialog
        size="lg"
        isOpen={isOpen}
        closePopup={closePopup}
        responseHandlerFn={handleResponse}
        caption={lastSelectedCategoryData && operation !== "add" ? "Update" : "Add"}
      >
        {lastSelectedCategoryData && operation !== "add" ? (
          <AddCategoryForm {...props}
            selectedCategory={lastSelectedCategoryData.category}
          ></AddCategoryForm>
        ) : (
          <AddCategoryForm {...props}></AddCategoryForm>
        )}
      </ModalDialog>
    </>
  );
}

export default ShowAllCategories;
