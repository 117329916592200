import { Button, ButtonGroup } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ModalDialog from "./Forms/ModalDialog";
function CommonButtonGroupNew(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [action, setAction] = useState(undefined);

  function handleDelete() {
    props.deleteFunction();
    setAction('delete');
  }

  function closePopup(json) {
    
    setIsClose(true);
    setIsOpen(false);
  }

  function handleAdd() {
    props.addFunction();
    setAction('add');
    setIsOpen(true);
  }

  function handleUpdate() {
    setAction('update');
    setIsOpen(true);
  }
  

  return (
    <>
    <ButtonGroup mt={2} maxH="100px" spacing={5} mb={5}>
      {props.isShowAdd && props.isShowAdd === "true" && (
        <Button
          height={8} onClick={handleAdd}
          variant="solid"
          colorScheme="blue"
          width={150}
        >
          {" "}
          Add{" "}
        </Button>
      )}
      {props.isShowUpdate && props.isShowUpdate=== "true" && (
        <Button isDisabled={props.selectedObject===undefined} onClick={handleUpdate} width={150} height={8} variant="solid" colorScheme="green">
          Update
        </Button>
      )}
      {props.isShowDelete && props.isShowDelete=== "true" &&(
        <Button isDisabled={props.selectedObject===undefined} onClick={handleDelete} width={150} height={8} variant="solid" colorScheme="red">
          Delete
        </Button>
      )}
    </ButtonGroup>
      <ModalDialog size="lg" responseHandlerFn={props.responseHandlerFn} action={action} isOpen={isOpen} closePopup={closePopup}>
            {props.children}
      </ModalDialog>
    </>
  );
}

export default CommonButtonGroupNew;
