import {
  Heading,
  Flex,
  Card,
  CardBody,
  Stack,
  CardFooter,
  Text,
  IconButton,
  chakra,
  useToast,
  SimpleGrid,
  Box,
  GridItem,
  Grid,
  Divider,
  HStack,
  Center,
  Image,
  TableCaption,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Button,
  ButtonGroup,
  Highlight,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import httpService from "../../services/httpService";
import EditUserForm from "../Forms/EditUserForm";
import CommonButtonGroup from "../CommonButtonGroup";
import ModalDialog from "../Forms/ModalDialog";
import { RxOpenInNewWindow } from "react-icons/rx";
import { useLocation } from "react-router-dom";

function ShowOrderDetails(props) {
  const [order, setOrder] = useState({});
  const [lastSelectedUserData, setlastSelectedUserData] = useState(undefined);
  const [orderSummary, setOrderSummary] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const toast = useToast();
  const location = useLocation();
  const CFaUserAlt = chakra(RxOpenInNewWindow);

  useEffect(() => {
    setOrder(location.state.order);
  }, []);

  useEffect(() => {
    if (order && order.orderedItems) {
      var orderProducts = order.orderedItems;
      var totalItems = order.orderedItems.length;
      var totalProducts = 0;
      var grossWeight = 0;
      var fineWeight = 0;
      var totalOtherCost = 0;
      for (var i = 0; i < orderProducts.length; i++) {
        var obj = orderProducts[i];
        grossWeight =
          grossWeight + parseFloat(obj.product.weight) * parseInt(obj.quantity);
        fineWeight =
          fineWeight +
          (parseFloat(obj.product.weight) *
            parseInt(obj.quantity) *
            (parseInt(obj.product.purity) + parseInt(obj.product.wastage))) /
            100;
        totalOtherCost = totalOtherCost + parseFloat(obj.product.otherCost) * obj.quantity;
        totalProducts = totalProducts + obj.quantity;
      }
      setOrderSummary({
        grossWeight: grossWeight,
        fineWeight: fineWeight,
        totalOtherCost: totalOtherCost,
        totalItems: totalItems,
        totalProducts: totalProducts,
      });
    }
  }, [order]);


  function handleUpdate(status) {
    httpService.post('order/admin/updateOrder',{
      orderStatus:status,
      id:order.id
    }).then((json)=>{
      var color=''
      if(status === 'In Process')
      {
        color = "orange.300"
      }
      else if(status === 'Rejected')
      {
        color = "red.400"
      }
      else if(status === 'Approved')
      {
        color = "blue.200"
      }
      else if(status === 'Completed')
      {
        color = "green.300"
      }    
      setOrder({...order,orderStatus:status,bgColor:color})
      toast({
        title: json.message,
        status: 'success',
        isClosable: true,
      });
    
    });
       
  }


  return (
    <Box width="100%" height="100%">
      <Box>
        <HStack>
      
        <Button
          height={8}
          onClick={() => handleUpdate('Approved')}
          variant="solid"
          colorScheme="blue"
          width={150}
          isDisabled={order.orderStatus==='Completed' || order.orderStatus==='Approved'}
        >
          Approve
        </Button>
        <Button isDisabled={order.orderStatus==='Completed' || order.orderStatus==='Rejected'} onClick={() => handleUpdate('Rejected')} width={150} height={8} variant="solid" colorScheme="red">
          Reject
        </Button>
        <Button isDisabled={order.orderStatus==='Completed'} onClick={() => handleUpdate('Completed')} width={150} height={8} variant="solid" colorScheme="green">
          Complete
        </Button>
      <Box flex="1">
      <Text mr={5} align="Right" fontSize={24} textColor={order.bgColor} fontWeight="bold">{order.orderStatus}</Text>
        
      </Box>
      </HStack>
      
      </Box>
     
      <Grid templateColumns="repeat(4, 1fr)">
        <GridItem colSpan={3}>
          <Flex flex={3} direction="row" flexWrap="wrap" overflow="auto">
            {Object.keys(order).length > 0 &&
              order.orderedItems.map((userOrder, index) => {
                return (
                  (userOrder.hidden === undefined ||
                    userOrder.hidden === false) && (
                    <Card
                      direction={{ base: "column", sm: "row" }}
                      borderColor="gray.200"
                      borderWidth={1}
                      mr={2}
                      mt={2}
                      _hover={{ bg: "blue.100", cursor: "pointer" }}
                      h="300px"
                      w="350px"
                    >
                      <Image
                        objectFit="contain"
                        width={150}
                        src={userOrder.product.images[0].url}
                        alt="Caffe Latte"
                      />
                      <CardBody>
                        <Stack spacing="1">
                          <Heading size="sm">{userOrder.product.title}</Heading>
                          <Text color="blue.700" fontSize="sm">
                            Code : {userOrder.product.code}
                          </Text>
                          <Text color="blue.700" fontSize="sm">
                            Other Cost : {userOrder.product.otherCost}
                          </Text>
                          <Text color="blue.700" fontSize="sm">
                            Purity : {userOrder.product.purity}
                          </Text>
                          <Text color="blue.700" fontSize="sm">
                            Wastage : {userOrder.product.wastage}
                          </Text>
                          .
                          <Text color="blue.700" fontSize="sm">
                            Quantity : {userOrder.quantity}
                          </Text>
                          <Text color="blue.700" fontSize="sm">
                            Unit Weight : {userOrder.product.weight}
                          </Text>
                        </Stack>
                      </CardBody>
                    </Card>
                  )
                );
              })}
          </Flex>
        </GridItem>

        <GridItem colSpan={1}>
          <TableContainer>
            <Table variant="simple">
              <TableCaption placement="top" fontSize={24}>
                Order Summary
              </TableCaption>
              <Tbody>
                <Tr>
                  <Td>Total Items</Td>
                  <Td>{orderSummary.totalItems}</Td>
                </Tr>
                <Tr>
                  <Td>Total Products</Td>
                  <Td>{orderSummary.totalProducts}</Td>
                </Tr>
                <Tr>
                  <Td>Gross Weight</Td>
                  <Td>{orderSummary.grossWeight}</Td>
                </Tr>
                <Tr>
                  <Td>Fine Weight</Td>
                  <Td>{orderSummary.fineWeight}</Td>
                </Tr>

                <Tr>
                  <Td>Other Cost</Td>
                  <Td>{orderSummary.totalOtherCost}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
      </Grid>
     
    </Box>
  );
}
export default ShowOrderDetails;
