import { ChakraProvider } from "@chakra-ui/react";
import Login from "./Components/Forms/Login";
import { Route, Routes } from "react-router-dom";
import ShowAllCategories from "./Components/pages/ShowAllCategories";
import SidebarWithHeader from "./Components/SIdebar/SidebarWithHeader";
import Home from "./Components/Home/home";
import NathTypeAndPurity from "./Components/pages/NathTypeAndPurity";
import ShowAllProducts from "./Components/pages/ShowAllProducts";
import ShowAllUsers from "./Components/pages/ShowAllUsers";
import ShowAllOrders from "./Components/pages/ShowAllOrders";
import ShowOrderDetails from "./Components/pages/ShowOrderDetails";
import {
  UserDetailsProvider,
  useUserContext,
} from "./Components/AppContext/AppContext";
import NavigationContext from "./Components/SIdebar/NavigationContext";

function App() {
  return (
    
      <ChakraProvider>
        <UserDetailsProvider>
        <NavigationContext></NavigationContext>
        </UserDetailsProvider>
      </ChakraProvider>
  );
}

export default App;
