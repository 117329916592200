import {
  Heading,
  Flex,
  Card,
  CardBody,
  Stack,
  Image,
  Text,
  SimpleGrid,
  Center,
  Box,
  Badge
} from "@chakra-ui/react";

function ShowAllProducts(props) {
  function handleCardSelection(e, product, index) {
    if (e.currentTarget) {
      if (e.currentTarget.style.backgroundColor === "rgb(190, 227, 248)") {
        e.currentTarget.style.backgroundColor = "#FFFFFF";
        e.currentTarget.style.borderWidth = "0px";
        e.currentTarget.style.borderColor = "#FFFFFF";
        props.handleSelect(undefined);
      } else {
        e.currentTarget.style.backgroundColor = "#BEE3F8";
        e.currentTarget.style.borderWidth = "2px";
        e.currentTarget.style.borderColor = "#BEE3F8";

        var clonedData = { ...props.lastSelectedProductData };
        clonedData.product = product;
        clonedData.domElement = e.currentTarget;
        clonedData.index = index;
        props.handleSelect(clonedData);
        if (
          props.lastSelectedProductData &&
          props.lastSelectedProductData.domElement
        ) {
          props.lastSelectedProductData.domElement.style.backgroundColor =
            "#FFFFFF";
          props.lastSelectedProductData.domElement.style.borderColor =
            "#FFFFFF";
          props.lastSelectedProductData.domElement.style.borderWidth = "0px";
        }
      }
    }
  }

  return (
    <Box height="100vh">
      {props.products && props.products.length > 0 ?
        <Flex  direction="row" flexWrap="wrap">
          {props.products &&
            props.products.length > 0 &&
            props.products.map((category, index) => {
              return (
                <Card
                  key={category.id}
                  borderColor="gray.200"
                  borderWidth={1}
                  mr={2}
                  mt={2}
                  onClick={(e) => {
                    handleCardSelection(e, category, index);
                  }}
                  _hover={{
                    bg: "blue.100",
                    cursor: "pointer",
                    borderWidth: "2px",
                    borderColor: "blue.100",
                  }}
                  width="300px"
                >
                  <Badge variant='solid' colorScheme='blue'><Center><Text fontWeight='bold' fontSize="12px">{category.code}</Text></Center></Badge> 
                  {category.images && category.images.length > 0 ? (
                    <Image
                      height={175}
                      objectFit='contain'
                      src={category.images[0].url}
                      alt="No Image Provided"
                      borderRadius="lg"
                    />
                  ) : (
                    <Image
                      height={175}
                      src=""
                      alt="No Image Provided"
                      borderRadius="5"
                    />
                  )}
                  <CardBody p={1}>
                    <Stack ml={2} mb={1} spacing="1">
                      <Center mb="3px"><Heading size="sm">{category.title}</Heading></Center>
                    </Stack>
                    <SimpleGrid ml={2} columns={2} spacing={3}>
                      <Text color="blue.700" fontSize="sm">
                        Gross Weight : {category.grossWeight.toFixed(3)}
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Purity : {category.purity}
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Net Weight : {category.weight.toFixed(3)}
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Wastage : {category.wastage} %
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Other Cost : {category.otherCost}
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Quantity : {category.availableQuantity}
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Width : {category.width.toFixed(3)} inch
                      </Text>
                      <Text color="blue.700" fontSize="sm">
                        Height : {category.height.toFixed(3)} inch
                      </Text>
                    </SimpleGrid>
                  </CardBody>
                </Card>
              );
            })}
        </Flex> : <Center height="700px"> <img  width="200px" src="/NoProductsImage.png"></img></Center>
      }
    </Box>
  );
}

export default ShowAllProducts;
