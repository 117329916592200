import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  VStack,
  Select,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import httpService from "../../services/httpService";
import { useNavigate } from "react-router-dom";

function AddEditUserForm(props) {
  const toast = useToast();
  const navigate = useNavigate();
  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }

  function handleError(msg) {
    if (msg && msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    }
    else
    {
      showToast(msg,"error");
    }
  }

  const formik = useFormik({
    initialValues: {
      firmName: props.selectedUser ? props.selectedUser.firmName : "",
      firmAddress: props.selectedUser ? props.selectedUser.firmAddress : "",
      email: props.selectedUser ? props.selectedUser.email : "",
      gstNumber: props.selectedUser ? props.selectedUser.gstNumber : "",
      username: props.selectedUser ? props.selectedUser.username : "",
      goldLimit: props.selectedUser ? props.selectedUser.goldLimit : "",
      status: props.selectedUser ? props.selectedUser.status : "",
      role:props.selectedUser ? props.selectedUser.assignedRoles[0] : 'user'
    },
    initialTouched:{goldLimit:true},
    validateOnMount:true,
    onSubmit: (values) => {
      if (props.selectedUser) {
        httpService
          .post(
            "users/admin/updateUser",
            getUpdatedUserData,
            undefined,
            undefined
          )
          .then((json) => {
            props.responseHandlerFn(json);
            props.closePopup();
            showToast("User update successful", "success");
          })
          .catch((msg) => {
            handleError(msg);
          });
      } else {
        var input = {};
        var clonedVaues = {};
        
        Object.assign(clonedVaues, formik.values);
        input['roles'] = [formik.values.role];
        delete clonedVaues['role']
        Object.keys(clonedVaues).forEach(
          (key) => (input[key] = clonedVaues[key])
        );
        httpService
          .post("users/admin/addUser", input, undefined, undefined)
          .then((json) => {
            props.responseHandlerFn(json);
            props.closePopup();
            showToast("User create successful", "success");
          })
          .catch((msg) => {
            handleError(msg);
          });
      }
    },
    validationSchema: Yup.object({
      firmName: Yup.string().required("Firm Name is required"),
      firmAddress: Yup.string().required("Firm Address is required"),
      email: Yup.string().email("Invalid Email address").required("Email is required"),
      gstNumber: Yup.string().required("GST number is required").test('isValidGST','GST number is invalid', (value)=>
      {
        const regex = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;
        var value =  value.match(regex);
        if(value === null)
        {
          return false;
        }
        return true;
      }),
      username: Yup.number().required("Enter a mobile number").test('isMoreThan10Digits','Enter 10 digits of mobile number', (value)=>
      {
          return value.toString().length === 10 
      }),
      goldLimit: Yup.number().required("Gold Limit is required").moreThan(0,"Gold Limit cannot be zero for user"),
      status: Yup.string().required("Status is required"),
    })
  });

  function getUpdatedUserData() {
    var updateUserData = {};
    updateUserData["status"] = formik.values.status;
    updateUserData["goldLimit"] = formik.values.goldLimit;
    updateUserData["username"] = formik.values.username;

    updateUserData["assignedRoles"]=[formik.values.role];
    return updateUserData;
  }

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={4}>
            <FormControl isInvalid={formik.touched.username && formik.errors.username}>
              <Input
              type='number'
                isDisabled={props.selectedUser !== undefined}
                maxLength={10}
                borderColor="black"
                id="username"
                name="username"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.username}
                placeholder="Mobile Number"
              />
              <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.firmName && formik.errors.firmName}>
              <Input
              textTransform="uppercase"
                isDisabled={props.selectedUser !== undefined}
                borderColor="black"
                id="firmName"
                onChange={formik.handleChange}
                name="firmName"
                onBlur={formik.handleBlur}
                value={formik.values.firmName}
                placeholder="Firm Name"
              />
              <FormErrorMessage>{formik.errors.firmName}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.firmAddress && formik.errors.firmAddress}>
              <Input
              textTransform="uppercase"
                isDisabled={props.selectedUser !== undefined}
                borderColor="black"
                id="firmAddress"
                onChange={formik.handleChange}
                name="firmAddress"
                onBlur={formik.handleBlur}
                value={formik.values.firmAddress}
                placeholder="Firm Address"
              />
              <FormErrorMessage>{formik.errors.firmAddress}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.email && formik.errors.email}>
              <Input
                isDisabled={props.selectedUser !== undefined}
                borderColor="black"
                id="email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="email"
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.gstNumber && formik.errors.gstNumber}
            >
              <Input
                textTransform="uppercase"
                borderColor="black"
                id="gstNumber"
                name="gstNumber"
                value={formik.values.gstNumber}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="GST Number"
              />
              <FormErrorMessage>{formik.errors.gstNumber}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.goldLimit && formik.errors.goldLimit}
            >
              <Input
                type="number"
                borderColor="black"
                id="goldLimit"
                name="goldLimit"
                value={formik.values.goldLimit}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Gold Limit"
              />
              <FormErrorMessage>{formik.errors.goldLimit}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formik.touched.role && formik.errors.role}>
              <Select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                id="role"
                name="role"
                placeholder="Select Role"
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="agent">Agent</option>
              </Select>
              <FormErrorMessage>{formik.errors.role}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.status && formik.errors.status}
            >
              <Select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
                id="status"
                name="status"
                placeholder="Select Status"
              >
                <option value="Temp">Temp</option>
                <option value="Inactive" selected="true">
                  Inactive
                </option>
                <option value="Active">Active</option>
              </Select>
              <FormErrorMessage>{formik.errors.status}</FormErrorMessage>
            </FormControl>

            <Button
              isLoading={props.showIsLoading}
              loadingText={props.selectedUser ? "Updating User":'Adding User'}
              isDisabled={
                Object.keys(formik.touched).length === 0 ||
                Object.keys(formik.errors).length > 0
              }
              type="submit"
              colorScheme="purple"
              width="full"
            >
              Submit
            </Button>
          </VStack>
        </form>
      </Box>
    </>
  );
}

export default AddEditUserForm;
