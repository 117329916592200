import React, { useEffect, useRef, useState } from "react";

import { Box, HStack, Input, useToast } from "@chakra-ui/react";
import ShowAllProducts from "../pages/ShowAllProducts";
import httpService from "../../services/httpService";
import CommonButtonGroupNew from "../CommonButtonGroupNew";
import AddEditProductForm from "../Forms/AddEditProductForm";

function SearchProductsDataProvider(props) {
  const [products, setProducts] = useState([]);
  const [lastSelectedProductData, setLastSelectedProductData] =
    useState(undefined);
  const toast = useToast();
  useEffect(() => {
    httpService.get("products/search/*", setProducts);
  }, []);

  function handleSelect(product) {
    setLastSelectedProductData(product);
  }

  function handleChange(value) {
    var code = value;
    if (code == "") {
      code = "*";
    }
    httpService.get("products/search/" + code, setProducts);
  }

  function handleResponse(json) {
    if (json) {
      var clonedData = [...products];
      if (lastSelectedProductData && lastSelectedProductData.product) {
        clonedData[lastSelectedProductData.index] = json;
      } else {
        clonedData.push(json);
      }
      setProducts(clonedData);
    }
  }

  function handleDelete() {
    if (lastSelectedProductData) {
      httpService
        .post(
          "products/admin/deleteProduct/" + lastSelectedProductData.product.id
        )
        .then(() => {
          var clonedData = [...products];
          clonedData.splice(lastSelectedProductData.index, 1);
          toast({
            title: "Product deleted",
            status: "success",
            isClosable: true,
          });
          setProducts(clonedData);
        })
        .catch(() => {
          toast({
            title: "Product delete failed",
            status: "error",
            isClosable: true,
          });
        });
    }
  }

  return (
    <>
      <Box h="5vh">
        <HStack spacing={3}>
          <Box width="50%">
            <Input
              type="number"
              onChange={(e) => handleChange(e.currentTarget.value)}
              placeholder="Enter Procuct Code to Search"
              size="md"
            />
          </Box>
          <CommonButtonGroupNew
            isShowAdd="false"
            isShowUpdate="true"
            isShowDelete="true"
            deleteFunction={handleDelete}
            responseHandlerFn={handleResponse}
            selectedObject={lastSelectedProductData?.product || undefined}
          >
            {lastSelectedProductData !== undefined ? (
              <AddEditProductForm {...props}
                selectedProduct={lastSelectedProductData.product}
              ></AddEditProductForm>
            ) : (
              <AddEditProductForm {...props}></AddEditProductForm>
            )}
          </CommonButtonGroupNew>
        </HStack>
      </Box>

      <ShowAllProducts
        {...props}
        lastSelectedProductData={lastSelectedProductData}
        handleSelect={handleSelect}
        products={products}
      ></ShowAllProducts>
      {}
    </>
  );
}

export default SearchProductsDataProvider;
