import { Outlet } from 'react-router-dom';
import SidebarWithHeader from './SidebarWithHeader';

const SidebarLayout = (props) => (
  <>
    <SidebarWithHeader {...props}>
    <Outlet />
    </SidebarWithHeader>
  </>
);

export default SidebarLayout;