import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

function ModalDialog(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Modal scrollBehavior="inside" isOpen={props.isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader height="10" bg="gray.100">
            {props.caption ? props.caption : 'Add'}
          </ModalHeader>
          <ModalCloseButton onClick={props.closePopup} />
          <ModalBody mt={5} mb={5}>
            {React.Children.map(props.children, (child) => {
              var renderable = React.isValidElement(child);
              if (renderable) {
                return React.cloneElement(child, {
                  closePopup: props.closePopup,
                  responseHandlerFn: props.responseHandlerFn,
                  action: props.action,
                });
              }
            })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalDialog;
