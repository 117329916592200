import { Button, ButtonGroup } from "@chakra-ui/react";

function CommonButtonGroup(props) {


  

  return (
    <ButtonGroup maxH="100px" spacing={5} mb={5}>
      {props.isShowAdd && props.isShowAdd === "true" && (
        <Button
          height={8} onClick={props.addFunction}
          variant="solid"
          colorScheme="blue"
          width={150}
        >
          {" "}
          Add{" "}
        </Button>
      )}
      {props.isShowUpdate && props.isShowUpdate=== "true" && (
        <Button isDisabled={props.selectedObject===undefined} onClick={props.updateFunction} width={150} height={8} variant="solid" colorScheme="green">
          Update
        </Button>
      )}
      {props.isShowDelete && props.isShowDelete=== "true" &&(
        <Button isDisabled={props.selectedObject===undefined} onClick={props.deleteFunction} width={150} height={8} variant="solid" colorScheme="red">
          Delete
        </Button>
      )}
    </ButtonGroup>


  );
}

export default CommonButtonGroup;
