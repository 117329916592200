import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import httpService from "../../services/httpService";
import { useNavigate } from "react-router-dom";

function AddNathTypeForm(props) {
  const toast = useToast();
  const navigate = useNavigate();

  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }
  function handleError(msg) {
    if (msg && msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }
  var initialValues = {};
  var valiidationSchema = {};
  if (props.type === "nath") {
    initialValues = {
      name: "",
      description: "",
    };
    valiidationSchema = Yup.object({
      name: Yup.string().required("Title is required"),
    });
  } else {
    initialValues = {
      purity: "",
    };
    valiidationSchema = Yup.object({
      purity: Yup.string().required("Purity is required"),
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (props.type === "nath") {
        httpService
          .post("categories/admin/addNathType", getInput())
          .then((json) => {
            props.responseHandlerFn(json);
            props.closePopup();
          })
          .catch((msg) => {
            handleError(msg);
          });
      } else {
        httpService
          .post("categories/admin/addPurity", getInput())
          .then((json) => {
            props.responseHandlerFn(json);
            props.closePopup();
          })
          .catch((msg) => {
            handleError(msg);
          });
      }
    },
    validationSchema: valiidationSchema,
  });

  function getInput() {
    var input = {};
    var values = formik.values;
    if (props.type === "nath") {
      input["name"] = values["name"];
      input["description"] = values["description"];
    } else {
      input["purity"] = values["purity"];
    }
    return input;
  }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing={4}>
          {props.type === "" ||
            (props.type === "nath" && (
              <FormControl
                isInvalid={formik.touched.name && formik.errors.name}
              >
                <Input
                  borderColor="black"
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Name"
                />
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              </FormControl>
            ))}
          {props.type === "" ||
            (props.type === "nath" && (
              <FormControl>
                <Input
                  borderColor="black"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Description"
                />
              </FormControl>
            ))}
          {props.type === "" ||
            (props.type === "purity" && (
              <FormControl>
                <Input
                  borderColor="black"
                  id="purity"
                  name="purity"
                  value={formik.values.purity}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="Purity"
                />
              </FormControl>
            ))}
          <Button
            isDisabled={
              Object.keys(formik.touched).length === 0 ||
              Object.keys(formik.errors).length > 0
            }
            onClick={() => {
              props.closePopup();
            }}
            type="submit"
            colorScheme="blue"
            width="full"
          >
            Add
          </Button>
        </VStack>
      </form>
    </Box>
  );
}

export default AddNathTypeForm;
