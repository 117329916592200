import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Formik, useFormik, useFormikContext, Form, Field } from "formik";
import httpService from "../../services/httpService";
import { useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";

function AddEditProductForm(props) {
  const toast = useToast();
  const navigate = useNavigate();

  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }
  function handleError(msg) {
    if (msg && msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }

  const fileRef = useRef(undefined);
  const formik = useFormik({
    initialValues: getInitialValues(),
    initalTouched: { code: true },

    onSubmit: (values) => {
      httpService
        .post(
          "products/admin/addProduct",
          getAddProductFormData,
          undefined,
          "multipart/form-data"
        )
        .then((json) => {
          props.responseHandlerFn(json);
          props.closePopup();
        })
        .catch((msg) => {
          handleError(msg);
        });
      console.log(values);
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      purity: Yup.string().required(),
      wastage: Yup.number().required().moreThan(-1, "Cannot be negative"),
      weight: Yup.number().required().moreThan(-1, "Cannot be negative"),
      height: Yup.number().required().moreThan(-1, "Cannot be negative"),
      width: Yup.number().required().moreThan(-1, "Cannot be negative"),
      code: Yup.number()
        .required()
        .test(
          "isValidProductCodeLength",
          "Product code must be 9 characters",
          (value) => {
            return value && value.toString().length === 9;
          }
        ),
      availableQuantity: Yup.number().moreThan(-1, "Cannot be negative"),
    }),
  });

  useEffect(() => {
    var isValidValueForCode = false;
    var isFileSelected =
      fileRef !== undefined &&
      fileRef.current !== undefined &&
      fileRef.current.files.length > 0;
    var withoutExtension = "";
    if (isFileSelected) {
      var name = fileRef.current.files[0].name;
      withoutExtension = name.substring(0, name.indexOf("."));
      if (!isNaN(withoutExtension)) {
        isValidValueForCode = true;
      }
    }
    if (isValidValueForCode) {
      formik.setFieldValue("code", parseInt(withoutExtension));
    }
  }, [formik.values.image]);

  function getInitialValues() {
    var initialValues = {
      title: "",
      purity: props.purity != undefined ? props.purity : "",
      wastage: "",
      weight: "",
      grossWeight: "",
      height: 0,
      width: 0,
      otherCost: "",
      availableQuantity: 0,
    };

    if (props.action !== "add") {
      initialValues = {
        code: props.selectedProduct ? props.selectedProduct.code : "",
        title: props.selectedProduct ? props.selectedProduct.title : "",
        purity: props.selectedProduct ? props.selectedProduct.purity : "",
        wastage: props.selectedProduct ? props.selectedProduct.wastage : "",
        weight: props.selectedProduct ? props.selectedProduct.weight : "",
        grossWeight: props.selectedProduct
          ? props.selectedProduct.grossWeight
          : "",
        height: props.selectedProduct ? props.selectedProduct.height : "",
        width: props.selectedProduct ? props.selectedProduct.width : "",
        category: props.selectedProduct ? props.selectedProduct.title : "",
        otherCost: props.selectedProduct ? props.selectedProduct.otherCost : "",
        availableQuantity: props.selectedProduct
          ? props.selectedProduct.availableQuantity
          : "",
      };
    }
    return initialValues;
  }

  function getAddProductFormData() {
    var formData = new FormData();
    if (props.selectedProduct) {
      formData.append("id", props.selectedProduct.id);
      formData.append("imageUpdated", formik.touched.image !== undefined);
    } else {
      formData.append("imageUpdated", true);
    }
    formData.append("file", fileRef.current.files[0]);
    var clonedVaues = {};
    Object.assign(clonedVaues, formik.values);
    formData.append("selectedCategoryId", props.selectedCategoryId);
    delete clonedVaues["category"];
    if (formik.values.grossWeight) {
      formData.append("grossWeight", clonedVaues["grossWeight"]);
    }
    if (formik.values.weight && formik.values.weight !== "") {
      formData.append("weight", clonedVaues["weight"]);
    } else {
      formData.append("grossWeight", clonedVaues["grossWeight"]);
    }
    delete clonedVaues["grossWeight"];
    delete clonedVaues["weight"];
    Object.keys(clonedVaues).forEach((key) =>
      formData.append(key, clonedVaues[key])
    );
    return formData;
  }

  const codeFiled = () => {};

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <VStack spacing={2}>
            <FormControl
              isInvalid={formik.touched.image && formik.errors.image}
            >
              <FormLabel>Image</FormLabel>
              <Input
                type="file"
                ref={fileRef}
                borderColor="black"
                id="image"
                name="image"
                value={formik.values.image}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Product Image"
                size="sm"
              />
            </FormControl>

            {props.action !== "add" &&
              props.selectedProduct &&
              props.selectedProduct.images.length > 0 && (
                <FormControl>
                  <Text color="blue.600" fontSize="xs">
                    Current Selection :{" "}
                    {props.selectedProduct.images[0].originalFileName}
                  </Text>
                </FormControl>
              )}

            <FormControl isInvalid={formik.touched.code && formik.errors.code}>
              <FormLabel>Code</FormLabel>
              <Input
                onWheel={(e) => e.target.blur()}
                borderColor="black"
                size="sm"
                id="code"
                name="code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Code"
              />
              <FormErrorMessage>{formik.errors.code}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.title && formik.errors.title}
            >
              <FormLabel>Title</FormLabel>
              <Input
                borderColor="black"
                size="sm"
                id="title"
                name="title"
                value={formik.values.title}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Title"
              />
              <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.purity && formik.errors.purity}
            >
              <FormLabel>Purity</FormLabel>
              <Input
                disabled={
                  props.purity !== undefined ||
                  props.selectedProduct.purity !== undefined
                }
                borderColor="black"
                size="sm"
                id="purity"
                name="purity"
                value={formik.values.purity}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Purity"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.purity}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.otherCost && formik.errors.otherCost}
            >
              <FormLabel>Other Cost</FormLabel>
              <Input
                type="number"
                size="sm"
                borderColor="black"
                id="otherCost"
                name="otherCost"
                value={formik.values.otherCost}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Other Cost"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.otherCost}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                formik.touched.availableQuantity &&
                formik.errors.availableQuantity
              }
            >
              <FormLabel>Available Quantity</FormLabel>
              <Input
                type="number"
                size="sm"
                borderColor="black"
                id="availableQuantity"
                name="availableQuantity"
                value={formik.values.availableQuantity}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Available Quantity"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>
                {formik.errors.availableQuantity}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={formik.touched.wastage && formik.errors.wastage}
            >
              <FormLabel>Wastage</FormLabel>
              <Input
                borderColor="black"
                size="sm"
                id="wastage"
                name="wastage"
                value={formik.values.wastage}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Wastage"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.wastage}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.weight && formik.errors.weight}
            >
              <FormLabel>Gross Weight</FormLabel>
              <Input
                type="number"
                size="sm"
                borderColor="black"
                id="grossWeight"
                name="grossWeight"
                value={formik.values.grossWeight}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Gross Weight"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.weight}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={
                formik.touched.grossWeight && formik.errors.grossWeight
              }
            >
              <FormLabel>Net Weight</FormLabel>
              <Input
                type="number"
                size="sm"
                borderColor="black"
                id="weight"
                name="weight"
                value={formik.values.weight}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Net Weight"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.grossWeight}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.height && formik.errors.height}
            >
              <FormLabel>Height</FormLabel>
              <Input
                type="number"
                size="sm"
                borderColor="black"
                id="height"
                name="height"
                value={formik.values.height}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Height"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.height}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={formik.touched.width && formik.errors.width}
            >
              <FormLabel>Width</FormLabel>
              <Input
                type="number"
                size="sm"
                borderColor="black"
                id="width"
                name="width"
                value={formik.values.width}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Width"
                onWheel={(e) => e.target.blur()}
              />
              <FormErrorMessage>{formik.errors.width}</FormErrorMessage>
            </FormControl>

            <Button
              loadingText={
                props.selectedProduct ? "Updating Product" : "Adding Product"
              }
              isLoading={props.showIsLoading}
              isDisabled={
                Object.keys(formik.touched).length === 0 ||
                Object.keys(formik.errors).length > 0
              }
              type="submit"
              colorScheme="purple"
              width="full"
            >
              Submit
            </Button>
          </VStack>
        </form>
      </Box>
    </>
  );
}

export default AddEditProductForm;
