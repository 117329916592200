import { Box, Center, Flex, Heading, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Home(props) {
  const navigate = useNavigate();

  return (
    <Box h="100vh">
    <Center mt="10%">
      <Flex direction="row" wrap="wrap">
        <Box _hover={{ bg: "blue.100", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/categories');} } borderRadius={15} marginEnd={20} bg="blue.100" height="200px" width={200}>
            <Center>
            <Heading mt="40%" size='md'>
                Categories
            </Heading>
            </Center>
        </Box>
        <Box _hover={{ bg: "blue.100", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/orders');} } borderRadius={15} marginEnd={20} bg="blue.100" height="200px" width={200}>
        <Center>
            <Heading mt="40%" size='md'>
                Orders
            </Heading>
            </Center>
        </Box>
        <Box _hover={{ bg: "blue.100", cursor: "pointer" }} borderWidth={1} onClick={() => { navigate('/Users');} } borderRadius={15} marginEnd={20} bg="blue.100" height="200px" width={200}>
        <Center>
            <Heading mt="40%" size='md'>
                Users
            </Heading>
            </Center>
        </Box>
        <Box _hover={{ bg: "blue.100", cursor: "pointer" }} borderWidth={1} borderRadius={15} marginEnd={20} onClick={() => { navigate('/search');} } bg="blue.100" height="200px" width={200}>
        <Center>
            <Heading mt="40%" size='md'>
                Search
            </Heading>
            </Center>
        </Box>  
      </Flex>
    </Center>
    </Box>
  );
}

export default Home;
