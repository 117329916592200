import {
  Button,
  Heading,
  Flex,
  Card,
  CardBody,
  Stack,
  Divider,
  CardFooter,
  ButtonGroup,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Center,
  VStack,
  StackDivider,
  Box,
  HStack,
  SimpleGrid,
  Checkbox,
} from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast,
  TableContainer,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import httpService from "../../services/httpService";
import ModalDialog from "../Forms/ModalDialog";
import AddNathTypeForm from "../Forms/AddNathTypeForm";
import { useNavigate } from "react-router-dom";

function NathTypeAndPurity() {
  const [nathTypesAndPurities, setNathTypesAndPurities] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState("");
  const [selectedPurityIndex, setSelectedPurityIndex] = useState([]);
  const [selectedNathTypeIndex, setSelectedNathTypeIndex] = useState([]);
  const [isClose, setIsClose] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  function handleError(msg) {
    if (msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }

  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }

  useEffect(() => {
    httpService
      .get("categories/getNathTypesAndPurity", setNathTypesAndPurities)
      .catch((msg) => {
        handleError(msg);
      });
  }, []);

  function setPopupParameters(type) {
    setIsOpen(true);
    setPopup(type);
  }

  function handleResponse(json) {
    if (json) {
      var clonedData = { ...nathTypesAndPurities };
      if (popup === "purity") {
        clonedData.purities.push(json);
      } else {
        clonedData.nathTypes.push(json);
      }
      setNathTypesAndPurities(clonedData);
    }
  }

  function handleDelete(type) {
    if (type === "nath") {
      var indexes = selectedNathTypeIndex;
      var nathToDelete = [];
      for (var i = 0; i < indexes.length; i++) {
        var nath = nathTypesAndPurities.nathTypes[indexes[i]];
        if (nath) {
          nathToDelete.push({ id: nath.id });
        }
      }
      httpService
        .post("categories/admin/deleteNaths", nathToDelete)
        .then(() => {
          var clonedData = { ...nathTypesAndPurities };
          indexes.sort();
          var newData = [];
          for (var i = 0; i < clonedData.nathTypes.length; i++) {
            
            var currIndex = indexes.indexOf(i);
            if(currIndex > -1)
            {
              continue;
            }
            else
            {
              newData.push(clonedData.nathTypes[i]);
            }
            
          }
          clonedData.nathTypes = newData;
          setNathTypesAndPurities(clonedData);
        })
        .catch((msg) => {
          handleError(msg);
        });
    } else {
      var indexes = selectedPurityIndex;
      var puritiesToDelete = [];
      for (var i = 0; i < indexes.length; i++) {
        var purity = nathTypesAndPurities.purities[indexes[i]];
        if (purity) {
          puritiesToDelete.push({ id: purity.id });
        }
      }
      httpService
        .post("categories/admin/deletePurities", puritiesToDelete)
        .then(() => {
          var clonedData = { ...nathTypesAndPurities };
          indexes.sort();
          var newData = [];
          for (var i = 0; i < clonedData.purities.length; i++) {
            
            var currIndex = indexes.indexOf(i);
            if(currIndex > -1)
            {
              continue;
            }
            else
            {
              newData.push(clonedData.purities[i]);
            }
            
          }
          clonedData.purities = newData;
          setNathTypesAndPurities(clonedData);
        })
        .catch((msg) => {
          handleError(msg);
        });
    }
  }

  function closePopup() {
    setIsClose(true);
    setIsOpen(false);
  }

  function handleCheckBoxSelection(e, type, index) 
  {
    if (type === "nath") 
    {
      var clonedData = [...selectedNathTypeIndex];
      if (e.currentTarget.checked && clonedData.indexOf(index) === -1) 
      {
        clonedData.push(index);
      } 
      else 
      {
        if (index > -1 && clonedData.indexOf(index) > -1) 
        {
          clonedData.splice(clonedData.indexOf(index), 1); 
        }
      }
      setSelectedNathTypeIndex(clonedData);
    } 
    else 
    {
      var clonedData = [...selectedPurityIndex];
      if (e.currentTarget.checked && clonedData.indexOf(index) === -1) 
      {
        clonedData.push(index);
      } 
      else 
      {
        if (index > -1 && clonedData.indexOf(index) > -1) 
        {
          clonedData.splice(clonedData.indexOf(index), 1); 
        }
      }
      setSelectedPurityIndex(clonedData);
    }
  }
  return (
    <>
      <SimpleGrid bg="white" columns={2}>
        <Box ml={20} mr={20}>
          <TableContainer border={1} borderColor="black">
            <Table variant="simple" colorScheme="black">
              <TableCaption placement="top">Nath Types</TableCaption>
              <Thead>
                <Tr>
                  <Th>Select</Th>
                  <Th>Name</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                {nathTypesAndPurities &&
                  nathTypesAndPurities.nathTypes &&
                  nathTypesAndPurities.nathTypes.sort((obj1,obj2)=>{
                    return obj1.name.localeCompare(obj2.name)
                  }).map((nathType, index) => {
                    return (
                      <Tr>
                        <Td>
                          {
                            <Checkbox
                              size="md"
                              colorScheme="green"
                              onChange={(e) => {
                                handleCheckBoxSelection(e, "nath", index);
                              }}
                            ></Checkbox>
                          }
                        </Td>
                        <Td>{nathType.name}</Td>
                        <Td>{nathType.description}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Center>
              <ButtonGroup spacing={10} mt={5} mb={2}>
                <Button
                  height={8}
                  onClick={() => setPopupParameters("nath")}
                  variant="solid"
                  colorScheme="blue"
                  width={150}
                >
                  Add
                </Button>
               
                <Button
                  width={150}
                  height={8}
                  onClick={() => handleDelete("nath")}
                  variant="solid"
                  colorScheme="red"
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Center>
          </TableContainer>
        </Box>
        <Box ml={20} mr={20}>
          <TableContainer>
            <Table variant="simple" colorScheme="black" borderColor="black">
              <TableCaption placement="top">Purity</TableCaption>
              <Thead>
                <Tr>
                  <Th>Select</Th>
                  <Th>Purity</Th>
                </Tr>
              </Thead>
              <Tbody>
                {nathTypesAndPurities &&
                  nathTypesAndPurities.purities &&
                  nathTypesAndPurities.purities.sort((obj1,obj2)=>{
                    return obj1.purity.localeCompare(obj2.purity)
                  }).map((purity, index) => {
                    return (
                      <Tr>
                        <Td>
                          {
                            <Checkbox
                              size="md"
                              colorScheme="green"
                              onChange={(e) => {
                                handleCheckBoxSelection(e, "purity", index);
                              }}
                            ></Checkbox>
                          }
                        </Td>
                        <Td>{purity.purity}</Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
            <Center>
              <ButtonGroup spacing={10} mt={5} mb={2}>
                <Button
                  height={8}
                  onClick={() => setPopupParameters("purity")}
                  variant="solid"
                  colorScheme="blue"
                  width={150}
                >
                  Add
                </Button>

                <Button
                  width={150}
                  height={8}
                  onClick={() => handleDelete("purity")}
                  variant="solid"
                  colorScheme="red"
                >
                  Delete
                </Button>
              </ButtonGroup>
            </Center>
          </TableContainer>
        </Box>
      </SimpleGrid>

      <ModalDialog
        isOpen={isOpen}
        responseHandlerFn={handleResponse}
        closePopup={closePopup}
      >
        <AddNathTypeForm type={popup}></AddNathTypeForm>
      </ModalDialog>
    </>
  );
}

export default NathTypeAndPurity;
