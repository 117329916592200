import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import httpService from "../../services/httpService";
import { useNavigate } from "react-router-dom";

function AddCategoryForm(props) {
  const [nathTypesAndPurities, setNathTypesAndPurities] = useState({});
  const fileRef = useRef(undefined);
  const toast = useToast();
  const navigate = useNavigate();
 
  const formik = useFormik({
    initialValues: {
      nathType: props.selectedCategory ? props.selectedCategory.name : "",
      purity: props.selectedCategory ? props.selectedCategory.purity : "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
    validationSchema: Yup.object({
      nathType: Yup.string().required("Nath Type is required"),
      purity: Yup.string().required("Purity is required"),
    }),
  });

  function showToast(msg, type) {
    toast({
      title: msg,
      status: type,
      isClosable: true,
    });
  }
  function handleError(msg) {
    if (msg && msg.startsWith("Session Expired")) {
      showToast(msg);
      navigate("/login");
    } else {
      showToast(msg, "error");
    }
  }

  useEffect(() => {
    httpService
      .get("categories/getNathTypesAndPurity", setNathTypesAndPurities)
      .catch((msg) => {
        handleError(msg);
      });
  }, []);

  function handleAddButtonClick(e) {
    httpService
      .post(
        "categories/admin/addCategory",
        getAddCategoryFormData,
        undefined,
        "multipart/form-data"
      )
      .then((json) => {
        props.responseHandlerFn(json);
        props.closePopup();
      })
      .catch((msg) => {
        handleError(msg);
      });
  }

  function getAddCategoryFormData() {
    var formData = new FormData();
    formData.append("file", fileRef.current.files[0]);

    if (props.selectedCategory) {
      formData.append("id", props.selectedCategory.id);
      //formData.append("id",props.selectedCategory.id);
      formData.append("imageUpdated", formik.touched.image===undefined ? false : true);
    } else {
      formData.append("imageUpdated", true);
    }

    var clonedVaues = formik.values;
    var nathType = undefined;
    for (var i = 0; i < nathTypesAndPurities.nathTypes.length; i++) {
      if (clonedVaues.nathType === nathTypesAndPurities.nathTypes[i].name) {
        nathType = nathTypesAndPurities.nathTypes[i];
        break;
      }
    }
    if (nathType) {
      formData.append("name", nathType.name);
      formData.append("description", nathType.description);
    }
    var purityObj = undefined;
    for (var i = 0; i < nathTypesAndPurities.purities.length; i++) {
      if (clonedVaues.purity === nathTypesAndPurities.purities[i].purity) {
        purityObj = nathTypesAndPurities.purities[i];
        break;
      }
    }
    if (purityObj) {
      formData.append("purity", purityObj.purity);
    }
    return formData;
  }
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing={4}>
          <FormControl isInvalid={formik.touched.image && formik.errors.image}>
            <Input
              type="file"
              ref={fileRef}
              borderColor="black"
              id="image"
              name="image"
              value={formik.values.image}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Product Image"
            />
          </FormControl>
          {props.selectedCategory &&
            props.selectedCategory.images.length > 0 && (
              <FormControl>
                <Text color="blue.600" fontSize="md">
                  Old file : {props.selectedCategory.images[0].originalFileName}
                </Text>
              </FormControl>
            )}
          <FormControl>
            <Select
              placeholder="Select Nath Type"
              borderColor="black"
              value={formik.values.nathType}
              id="nathType"
              name="nathType"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            >
              {nathTypesAndPurities &&
                nathTypesAndPurities.nathTypes &&
                nathTypesAndPurities.nathTypes.map((nathType, index) => {
                  return (
                    <option key={nathType.id} value={nathType.name}>
                      {nathType.name}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl>
            <Select
              placeholder="Select Purity"
              borderColor="black"
              value={formik.values.purity}
              id="purity"
              name="purity"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            >
              {nathTypesAndPurities &&
                nathTypesAndPurities.purities &&
                nathTypesAndPurities.purities.map((purityObj, index) => {
                  return (
                    <option key={purityObj.id} value={purityObj.purity}>
                      {purityObj.purity}
                    </option>
                  );
                })}
            </Select>
          </FormControl>

          <Button
            isLoading={props.showIsLoading}
            loadingText={
              props.selectedCategory ? "Updating Category" : "Adding Category"
            }
            isDisabled={
              Object.keys(formik.touched).length === 0 ||
              Object.keys(formik.errors).length > 0
            }
            onClick={(e) => {
              handleAddButtonClick(e);
            }}
            type="submit"
            colorScheme="blue"
            width="full"
          >
            Submit
          </Button>
        </VStack>
      </form>
    </Box>
  );
}

export default AddCategoryForm;
